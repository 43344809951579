.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rect-tooltip {
  z-index: 99999 !important;
}
.right-arrow {
  width: 0;
  height: 0;
  position: absolute;
  right: -12px;
  border-radius: 2px;
  top: 11px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  rotate: -225deg;
  border-top: 15px solid white;
}
.left-arrow {
  width: 0;
  height: 0;
  position: absolute;
  left: -12px;
  border-radius: 2px;
  top: 11px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  rotate: 223deg;
  border-top: 15px solid white;
}
.message-view::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #eaeaea;

  background-color: #eaeaea;
}
.message-view::-webkit-scrollbar {
  width: 5px;
  background-color: #eaeaea;
}
.message-view::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 15pt;
}
.tooltip {
  box-shadow: 0px 2px 7px 0px #0000001f !important;
  background-color: #ffffff !important;
  background: #ffffff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16.41px !important;
  text-align: left !important;
  color: #2b61ab !important;
}
.triangle {
  width: 0;
  height: 0;
  position: absolute;
  left: 22px;
  border-radius: 10px;
  bottom: -33px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #ffffff;
}

.tooltip-chart {
  background: #ffffff;
  padding: 20px 25px !important;
  box-shadow: 0px 8px 16px 0px #3232470f;
}
