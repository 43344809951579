@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Black.woff2") format("woff2"),
    url("../fonts/Roboto-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light.woff2") format("woff2"),
    url("../fonts/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Thin.woff2") format("woff2"),
    url("../fonts/Roboto-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

:root {
  --primary: #2b61ab;
  --secndary: #437fd0;
  --color-white: #fff;
  --color-black: #000;
  --font-family: "Roboto", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

ul,
ul li,
ol,
ol li {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

a,
a:hover,
svg path {
  text-decoration: none;
  -webkit-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

img {
  height: auto;
  width: 100%;
  max-width: 100%;
}

img,
svg {
  vertical-align: middle;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
img,
blockquote,
figure,
label {
  text-decoration: none;
  padding: 0px;
  margin: 0px;
  font-weight: normal;
  vertical-align: middle;
}

body {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
}

.main-header {
  background: var(--primary);
  padding: 20px 0;
  position: sticky;
  top: 0;
  width: calc(100% - 88px);
  margin-left: auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.open-sidebar.main-header {
  padding-left: 140px;
}
.basic-header {
  padding-left: 100px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.open-sidebar.basic-header {
  padding-left: 240px;
}
.main-header__row,
.main-header__left-user,
.sidebar__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.main-header__row {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.main-header__left {
  margin-left: 30px;
}
.main-header__left-user,
.sidebar__top {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.main-header__left-user figure img {
  width: 50px;
  height: 50px;
  border-radius: 16px;
  object-fit: cover;
  object-position: center center;
  margin-right: 20px;
}
.main-header__left-user div h6 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 5px;
  color: var(--color-white);
  text-transform: capitalize;
}
.main-header__left-user div a {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  color: var(--color-white);
}
.main-header__right img {
  max-height: 132px;
  width: auto;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 88px;
  background-color: var(--primary);
  overflow-y: auto;
  height: 100vh;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;
  padding: 0 12px 40px;
}
.sidebar ul li {
  margin: auto auto 10px;
  display: block;
}
.open-sidebar.sidebar ul.bg-white li {
  margin: auto auto 10px;
  display: flex;
}
.sidebar ul.bg-white li span {
  display: none;
}
.open-sidebar.sidebar ul.bg-white li span {
  display: block;
}
.sidebar ul li a,
.sidebar ul li button {
  width: 100%;
  padding: 14px;
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  border: 1px solid transparent;
}
.sidebar ul li button {
  margin-left: 1px;
  padding: 14px 16px;
}
.open-sidebar.sidebar {
  width: 240px;
}
.sidebar__top figure img {
  height: 25px;
  width: auto;
}
.sidebar__top figure {
  width: 0;
}
.open-sidebar .sidebar__top figure {
  width: auto;
}

.sidebar__list ul li a span,
.sidebar__list ul li button span {
  text-align: left;
  flex: 1;
  font-family: var(--font-family);
  font-size: 0;
  font-weight: 600;
  line-height: 1.1;
  color: var(--secndary);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.open-sidebar .sidebar__list ul li a span,
.open-sidebar .sidebar__list ul li button span {
  font-size: 16px;
}
.sidebar__list ul li a.active span,
.sidebar__list ul li button.active span,
.sidebar__list ul li a:hover span,
.sidebar__list ul li button:hover span {
  color: var(--color-white);
}
.open-sidebar .sidebar__list ul li a.active,
.open-sidebar .sidebar__list ul li button.active,
.open-sidebar .sidebar__list ul li a:hover,
.open-sidebar .sidebar__list ul li button:hover {
  background: var(--secndary);
  border: 1px solid var(--color-white);
}
.sidebar svg {
  height: 24px;
  width: auto;
  margin: 0 auto;
  display: block;
}
.open-sidebar .sidebar svg {
  margin: 0 22px 0 0;
}
.sidebar ul li a:hover svg path,
.sidebar ul li button:hover svg path,
.sidebar ul li button.active svg path,
.sidebar ul li a.active svg path {
  fill: var(--color-white);
}
.sidebar ul li a.logout:hover svg path:first-child,
.sidebar ul li a.logout.active svg path:first-child {
  fill: transparent;
}

.sidebar__list .bg-white li:last-child a:hover svg path:first-child,
.sidebar__list .bg-white li:last-child a.active svg path:first-child,
.sidebar__list .bg-white li:last-child button:hover svg path:first-child,
.sidebar__list .bg-white li:last-child button.active svg path:first-child {
  stroke: var(--color-white);
}
.sidebar ul.bg-white li a.active,
.sidebar ul.bg-white li a:hover,
.sidebar ul.bg-white li button.active,
.sidebar ul.bg-white li button:hover {
  background: var(--primary);
  box-shadow: 0px 4px 4px 0px #00000014;
}
.sidebar__top {
  padding: 25px 15px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.open-sidebar .sidebar__top,
.open-sidebar .sidebar ul li a {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  -webkit-justify-content: left;
  justify-content: left;
}
.sidebar__top a {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.main-header__responsive figure img {
  max-height: 25px;
  width: auto;
}
.open-sidebar .sidebar__top a {
  width: fit-content;
}
.sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--primary);
  background-color: var(--primary);
}
.sidebar::-webkit-scrollbar {
  width: 0px;
  background-color: var(--primary);
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: var(--color-white);
}
.sidebar__list .bg-white {
  background: var(--color-white);
  padding: 2px;
  border-radius: 15px;
  margin-top: 22px;
}
.main-header__responsive {
  display: none;
}
.main-header__responsive .hamburger-icon svg {
  width: auto;
  height: 24px;
}
.hamburger-icon svg {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.open-sidebar .hamburger-icon svg {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' width='50px' height='50px'%3E%3Cpath d='M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z' fill='%23fff' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.open-sidebar .hamburger-icon svg path {
  opacity: 0;
}
@media screen and (min-width: 1025px) {
  .sidebar__list ul:not(.bg-white) li a {
    width: fit-content;
  }
}
@media screen and (max-width: 1024px) {
  .basic-header {
    padding-left: 0px;
  }
  .main-header {
    z-index: 11;
  }
  .hamburger-desktop,
  .main-header__left,
  .main-header__right {
    display: none;
  }
  .main-header__responsive {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .main-header,
  .open-sidebar .main-header {
    width: 100%;
    padding: 15px;
  }
  .main-header__left {
    margin-left: 0;
  }
  .sidebar {
    width: 100%;
    padding: 15px 12px 60px;
    opacity: 0;
    /* position: relative; */
    z-index: -1;
    left: -100%;
  }
  .open-sidebar .sidebar {
    width: 100%;
    opacity: 1;
    z-index: 1;
    left: 0;
  }
}
