@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Rambla:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rdt_TableCol {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #343a40;
}
.rdt_TableCell {
  border-top: 1px solid #eaeaea;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #11263c;
  padding: 15px 5px;
}
.rdt_TableRow {
  cursor: pointer;
}
.rdt_TableRow:hover {
  cursor: pointer;
}
.rdt_TableRow:hover::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 3px;
  top: 0;
  left: 0;
  display: none;
  --tw-bg-opacity: 1;
  background-color: rgb(3 117 255 / var(--tw-bg-opacity));
}
.rdt_TableRow:hover {
  background-color: #eff6ffcc !important;
}
.img-avatar-header {
  width: 60px;
  height: 60px;
  gap: 0px;
  background: linear-gradient(241.23deg, #d9d9d9 -28.73%, #737373 63.09%);
  font-family: Roboto;
  font-size: 31.54px;
  font-weight: 700;
  line-height: 39.43px;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-avatar {
  width: 60px;
  height: 60px;
  gap: 0px;
  border-radius: 50%;
  background: linear-gradient(241.23deg, #d9d9d9 -28.73%, #737373 63.09%);
  font-family: Roboto;
  font-size: 31.54px;
  font-weight: 700;
  line-height: 39.43px;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-avatar-small {
  width: 20px;
  height: 20px;
  gap: 0px;
  border-radius: 50%;
  background: linear-gradient(241.23deg, #d9d9d9 -28.73%, #737373 63.09%);
  font-family: Roboto;
  font-size: 11.54px;
  font-weight: 700;
  line-height: 39.43px;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shadow-2xl {
  box-shadow: 0px 20px 80px 0px #0000000a;
}
.hover-effect:hover {
  background-color: #eff6ffcc !important;
}
.hover-effect.active {
  background-color: #eff6ffcc !important;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-weekdays,
.flatpickr-weekday,
.flatpickr-month,
.flatpickr-monthDropdown-months,
.flatpickr-monthDropdown-month {
  background: #2b61ab !important;
  border-color: #2b61ab !important;
}
.flatpickr-weekday {
  color: white !important;
}
